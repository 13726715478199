// import { EventBus } from '@/helpers'
// import Logger from '@/services/Logger'
// import Config from 'config'

let $
if (process.client) {
  $ = require('jquery')
}

function setScript (vm) {
  let country = getCountry(vm)
  if (!country) {
    // no script found for country
    // in this case button is not visible
  } else {
    return `https://static-${country}.payments-amazon.com/checkout.js`
  }
}

function getCountry (vm) {
  return vm.$store.getters['pl_amazon/getCheckoutSessionExtension']
}

// function scriptWidget (vm) {
//   const country = enableCountry(vm)
//   const isSandbox = vm.$store.getters['pl_amazon/isSandbox'] ? 'sandbox/' : ''
//   let scriptWidget = Config.Theme.amazon.scriptWidget
//   switch (country) {
//     case 'us':
//       scriptWidget = Config.Theme.amazon.scriptWidgetUS
//       break
//     case 'jp':
//       scriptWidget = Config.Theme.amazon.scriptWidgetJP
//   }
//   return scriptWidget.replace('{country}', country).replace('{isSandbox}', isSandbox)
// }

// function getRegion (vm) {
//   const country = enableCountry(vm)
//   return Config.Theme.amazon.options.regions[country] || 'EU'
// }

// function configAmazonLogin (vm) {
//   global.amazon.Login.setClientId(vm.$store.getters['pl_amazon/clientId'])
//   global.amazon.Login.setSandboxMode(vm.$store.getters['pl_amazon/isSandbox'])
//   global.amazon.Login.setRegion(getRegion(vm))
//   global.amazon.Login.setUseCookie(true)
// }

// let amazonLoginReady = false
// let amazonPaymentReady = false
export default class AmazonScript {
  static initAmazonScript (vm) {
    return new Promise(async (resolve) => {
      await $.getScript(setScript(vm))
        .done(() => {
          resolve(true)
        })
    })
  }

  static initAmazonPayConfig (vm) {
    return new Promise((resolve) => {
      return vm.$store.dispatch('pl_amazon/init')
        .finally(() => {
          resolve(true)
        })
    })
  }

  static load (vm, { mode = 'checkout' }) {
    // Logger.debug('try to load amazon script')
    // if (amazonLoginReady) {
    //   EventBus.$emit('amazon:loginready', global.amazon)
    // }
    // if (amazonPaymentReady) {
    //   EventBus.$emit('amazon:paymentready', global.OffAmazonPayments)
    // }
    // if (global.amazonLoading !== undefined) {
    //   return false
    // }

    // global.onAmazonLoginReady = function () {
    //   amazonLoginReady = true
    //   configAmazonLogin(vm)
    //   EventBus.$emit('amazon:loginready', global.amazon)
    // }

    // global.onAmazonPaymentsReady = function () {
    //   amazonPaymentReady = true
    //   EventBus.$emit('amazon:paymentready', global.OffAmazonPayments)
    //   // $(window).trigger('OffAmazonPayments')
    // }

    // Logger.debug('load amazon script')
    // global.amazonLoading = $.getScript(scriptWidget(vm))
    const promiseArr = []
    promiseArr.push(this.initAmazonScript(vm))
    promiseArr.push(this.initAmazonPayConfig(vm))
    return Promise.allSettled(promiseArr)
      .then(() => {
        if (vm.$route.params.lang) {
          global.$cookies.set('AMZ::locale', vm.$route.params.lang)
        }
      })
  }
}
